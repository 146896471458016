









































































































































































































































































































































































import { ConfirmModal, FormBlock, Scrollbar, Tabs, TwButton } from '@/app/components';
import { useAxios, useFeatureFlags, useQuery, useResult } from '@/app/composable';
import { tokenScopes } from '@/app/constants/scopes';
import store from '@/app/store';
import Keycloak from '@/modules/auth/api/keycloak';
import { computed, defineComponent, reactive, ref } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { confirmed, max, min, required } from 'vee-validate/dist/rules';
import { AccessTokenScopes, AccessToken } from '.';
import { AccessTokenAPI, AuthAPI } from '../api';
import GET_USER_DEPARTMENTS from '../graphql/getUserDepartments.graphql';
import { AccessTokenScope } from '../types/access-token-scope.interface';

extend('required', {
    ...required,
    message: '{_field_} is required',
});

extend('min', {
    ...min,
    message: '{_field_} must be longer than or equal to 8 characters.',
});
extend('max', {
    ...max,
    message: '{_field_} must be less than or equal to 24 characters.',
});
extend('confirmed', { ...confirmed, message: 'Repeat Password does not match.' });

export default defineComponent({
    name: 'EditUser',
    metaInfo: {
        title: 'Edit User Details',
    },
    components: {
        FormBlock,
        ValidationProvider,
        ValidationObserver,
        OrbitSpinner,
        TwButton,
        ConfirmModal,
        Tabs,
        AccessTokenScopes,
        AccessToken,
        Scrollbar,
    },
    setup(props, { root }) {
        const { exec, loading, error } = useAxios(true);
        const { areAnyEnabled } = useFeatureFlags();
        const user = ref(store.state.auth.user);
        const removeToken = ref(false);
        const showDeleteTokenModal = ref(false);
        const tokenToBeDeleted = ref(null);
        const runnerToBeDeleted = ref(null);
        const useKeycloak = computed(() => Keycloak.isEnabled());
        const availableScopes = computed(() => tokenScopes.filter((tokenScope) => areAnyEnabled(tokenScope.features)));
        const isAccessTokensAvailable = computed(() => availableScopes.value.length > 0);

        const tabs = computed(() => {
            const availableTabs = [{ id: 0, title: 'User Profile' }];
            if (isAccessTokensAvailable.value) availableTabs.push({ id: 1, title: 'Access Tokens' });
            return availableTabs;
        });
        const activeTab = ref(0);

        const createToken = ref(false);
        const generatedToken = ref(false);
        const tokenGeneration: any = reactive({
            name: null,
            token: '',
            scopes: [],
        });
        const accessTokens: any = ref([]);

        const totalScopes = ref(availableScopes.value.map((scope) => ({ ...scope, checked: false })));

        const retrieveTokens = async () => {
            accessTokens.value = [];
            try {
                const res = await exec(AccessTokenAPI.retrieveTokens());
                accessTokens.value = res?.data;
            } catch (e: any) {
                (root as any).$toastr.e('An error occurred.', 'Error');
            }
        };

        const userRef = ref<any>(null);
        const userUpdateDetails = reactive({
            firstName: user.value.firstName,
            lastName: user.value.lastName,
        });
        const passwordChange = reactive({ password: null, newPassword: null, repeatPassword: null });
        const passwordRef = ref<any>(null);
        const { loading: departmentsLoading, error: departmentsError, result } = useQuery(
            GET_USER_DEPARTMENTS,
            { id: user.value.id },
            { fetchPolicy: 'no-cache' },
        );
        const departments = useResult(result, null, (data: any) => data.userDepartments);

        // Methods

        const changePassword = async () => {
            const valid = await passwordRef.value.validate();
            if (valid) {
                try {
                    const { exec: execUpdate } = useAxios(true);
                    await execUpdate(AuthAPI.changePassword(passwordChange));

                    (root as any).$toastr.s('Password has been changed successfuly', 'Success');
                } catch (e) {
                    (root as any).$toastr.e('Invalid password', 'Error');
                } finally {
                    passwordChange.password = null;
                    passwordChange.newPassword = null;
                    passwordChange.repeatPassword = null;

                    passwordRef.value.reset();
                }
            }
        };

        const saveChanges = async () => {
            const valid = userRef.value ? await userRef.value.validate() : true;

            if (valid) {
                user.value = { ...user.value, ...userUpdateDetails };
                try {
                    // if there is also change on user details update them too
                    if (userRef.value) {
                        exec(AuthAPI.updateUser(user.value));
                        store.commit.auth.SET_USER(user.value);
                    }

                    (root as any).$toastr.s('User data have been changed successfuly', 'Success');
                } catch (e) {
                    (root as any).$toastr.e('Changing user data failed', 'Error');
                }
            }
        };

        const checkDifference = computed(() => {
            return (
                userUpdateDetails.firstName === user.value.firstName &&
                userUpdateDetails.lastName === user.value.lastName
            );
        });

        const cancel = async () => {
            root.$router.go(-1);
        };

        const showTokenGeneration = () => {
            createToken.value = true;
        };

        const generateToken = () => {
            const scopes: Array<any> = totalScopes.value
                .filter((scope: AccessTokenScope) => scope.checked)
                .map((scope: AccessTokenScope) => scope.name);

            const payload = {
                name: tokenGeneration.name,
                scopes,
            };

            exec(AccessTokenAPI.generateToken(payload))
                .then(async (res: any) => {
                    tokenGeneration.token = res.data.key;
                    tokenGeneration.name = null;

                    createToken.value = false;
                    generatedToken.value = true;
                    await retrieveTokens();
                })
                .catch(() => {
                    (root as any).$toastr.e('The token could not be generated due to an error.', 'Error');
                });
        };

        const deleteToken = (id: number) => {
            exec(AccessTokenAPI.deleteToken(id))
                .then(async () => await retrieveTokens())
                .catch(() => {
                    (root as any).$toastr.e('The token could not be deleted due to an error.', 'Error');
                })
                .finally(() => {
                    tokenToBeDeleted.value = null;
                    createToken.value = false;
                    showDeleteTokenModal.value = false;
                });
        };

        const showScopes = (scopes: any) => {
            let finalScopes = '';
            for (let i = 0; i < scopes.length; i += 1) {
                if (i === scopes.length - 1) {
                    finalScopes += scopes[i];
                } else {
                    finalScopes += `${scopes[i]}, `;
                }
            }

            return finalScopes;
        };

        const checkScopes = computed(() => {
            for (let i = 0; i < totalScopes.value.length; i += 1) {
                if (totalScopes.value[i].checked) {
                    return false;
                }
            }
            return true;
        });

        const tabClicked = (idx: number) => {
            activeTab.value = idx;
        };

        retrieveTokens();

        return {
            user,
            loading,
            error,
            departments,
            passwordChange,
            changePassword,
            saveChanges,
            userUpdateDetails,
            cancel,
            departmentsLoading,
            departmentsError,
            userRef,
            passwordRef,
            tabs,
            activeTab,
            generatedToken,
            showTokenGeneration,
            tokenGeneration,
            generateToken,
            createToken,
            deleteToken,
            removeToken,
            showDeleteTokenModal,
            accessTokens,
            tokenToBeDeleted,
            runnerToBeDeleted,
            showScopes,
            totalScopes,
            checkDifference,
            checkScopes,
            tabClicked,
            useKeycloak,
            isAccessTokensAvailable,
        };
    },
});
